import { graphql } from 'gatsby';

import React, { Component } from 'react';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import { PageHero, Introduction, FlowerPattern } from '../common/components/';
import KanItems from './KanItems';
import { getInternalUrl } from '../utils';

import * as flowerGold1 from '../assets/flower-1-black.svg';
import * as flowerGold2 from '../assets/flower-1-black.svg';

interface Props {
  data: any;
  location: any;
}

class Kan extends Component<Props, any> {
  render() {
    const { data } = this.props;

    const body = get(data, 'page.data.body');
    const lang = get(data, 'page.lang', 'en-us');
    const link = get(data, 'primary.experience_link.document[0].data.url');
    const linkText = get(data, 'primary.experience_link_text');

    const kanIntroductionBackgrounds = [
      <FlowerPattern
        key="bg_1"
        src={flowerGold1}
        height={'180px'}
        width={'auto'}
        right={'94%'}
        bottom={'20px'}
        opacity={0.6}
      />,
      <FlowerPattern
        key="bg_2"
        src={flowerGold2}
        height={'180px'}
        width={'auto'}
        right={'-10px'}
        bottom={'25px'}
        opacity={0.6}
      />,
    ];

    return (
      <Layout data={data} location={this.props.location}>
        {Array.isArray(body) &&
          body.map(slice => {
            switch (slice.__typename) {
              case 'PrismicKanBodyHero':
                return (
                  <PageHero key={slice.id + slice.__typename} data={slice} />
                );
              case 'PrismicKanBodyIntroduction':
                return (
                  <Introduction
                    key={slice.id + slice.__typename}
                    title={get(slice, 'primary.title', '')}
                    description={get(slice, 'primary.description', '')}
                    backgrounds={kanIntroductionBackgrounds}
                    link={getInternalUrl(
                      get(
                        slice,
                        'primary.experience_link.document[0].data.url',
                        lang
                      )
                    )}
                    linkText={get(slice, 'primary.experience_link_text')}
                  />
                );
              case 'PrismicKanBodyKanItems':
                return (
                  <KanItems
                    key={slice.id + slice.__typename}
                    data={slice}
                    lang={lang}
                  />
                );

              default:
                return null;
            }
          })}
      </Layout>
    );
  }
}

export default Kan;

// Query metadata for the homepage
export const query = graphql`
  query KanQuery($slug: String!) {
    page: prismicKan(id: { eq: $slug }) {
      ...Kan
    }
  }
`;
