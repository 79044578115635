import React from 'react';
import styled, { withTheme } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { get } from 'lodash';
import { Row, Col } from 'antd';
import { Title, Paragraph, FlowerPattern, Image, Link } from '../common/components';
import { getInternalUrl } from '../utils';

import flowerGold1 from '../assets/flower-3-gold.svg';
import flowerGold2 from '../assets/flower-2-gold.svg';

const Container = styled.div`
  position: relative;
`;

const BackButton = styled(Link)`
  color: ${props => props.theme.color.text.light};
  font-size: 18px !important;
  text-decoration: underline;
  margin-top: 3%;
  margin-bottom: 7%;
`;

const ItemTitle = styled(Title)`
  font-weight: bold !important;
  text-transform: uppercase;
`;

const ItemContent = styled(Paragraph)``;

const KanItemsRow = styled(Row)`
  padding: 0 8%;
  ${breakpoint('xs')`
    padding: 0 5%;
  `}
`;

const ItemCol = styled(Col)`
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-bottom: 20px;
  ${breakpoint('md')`
    margin-bottom: 50px;
  `}
  overflow: hidden !important;
`;

const ItemImage = styled(Image)`
  width: 280px !important;
  height: auto;
  ${breakpoint('sm')`
    width: 300px !important;
  `}
`;

const ItemImageContainer = styled.div`
  width: 280px !important;
  ${breakpoint('sm')`
    width: 300px !important;
  `}
`;

const ItemContentContainer = styled.div`
  margin-top: 1em !important;
  width: 280px !important;
  ${breakpoint('xl')`
    ${props => (props.isRight ? 'padding-right: 25px !important;' : 'padding-left: 25px !important;')}
        align-self: flex-start !important;
        margin-top: 0px !important;
    `}

  ${breakpoint('sm')`
    width: 300px !important;
  `}
`;

const ItemContainer = styled.div`
  display: none !important;
  align-items: center !important;
  flex-direction: column !important;

  ${breakpoint('xs')`
    ${props => !props.largeScreen && 'display: flex !important;'}
  `}

  ${breakpoint('xl')`
    flex-direction: row !important;
    ${props => props.largeScreen && 'display: flex !important;'}
    ${props => !props.largeScreen && 'display: none !important;'}
  `}
`;

interface Props {
  data: any;
  theme: any;
  lang?: string;
}

class KanItems extends React.Component<Props, any> {
  renderKanItems = () => {
    const { data } = this.props;
    const kanItems = get(data, 'items', []);
    return kanItems.map((kanItem, idx) => {
      const imgData = get(kanItem, 'image');
      return (
        <ItemCol xs={24} md={12} key={idx}>
          <ItemContainer largeScreen={false}>
            <ItemImageContainer>
              <ItemImage key={idx} data={imgData} alt={get(kanItem, 'alt', 'Kan Restaurant')} />
            </ItemImageContainer>
            <ItemContentContainer style={{ textAlign: 'left' }} isRight={false}>
              <ItemTitle level={3}>{get(kanItem, 'item_title', '')}</ItemTitle>
              <ItemContent small>
                <span
                  dangerouslySetInnerHTML={{
                    __html: get(kanItem, 'description.html', ''),
                  }}
                />
              </ItemContent>
            </ItemContentContainer>
          </ItemContainer>
          <ItemContainer largeScreen>
            {get(kanItem, 'image_position', '') === 'right' ? (
              <ItemContentContainer style={{ textAlign: 'right' }} isRight>
                <ItemTitle level={3}>{get(kanItem, 'item_title', '')}</ItemTitle>
                <ItemContent small>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: get(kanItem, 'description.html', ''),
                    }}
                  />
                </ItemContent>
              </ItemContentContainer>
            ) : (
              <ItemImageContainer>
                <ItemImage key={idx} data={imgData} alt={get(kanItem, 'alt', 'Kan Restaurant')} />
              </ItemImageContainer>
            )}
            {get(kanItem, 'image_position', '') !== 'right' ? (
              <ItemContentContainer style={{ textAlign: 'left' }} isRight={false}>
                <ItemTitle level={3}>{get(kanItem, 'item_title', '')}</ItemTitle>
                <ItemContent small>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: get(kanItem, 'description.html', ''),
                    }}
                  />
                </ItemContent>
              </ItemContentContainer>
            ) : (
              <ItemImageContainer>
                <ItemImage key={idx} data={imgData} alt={get(kanItem, 'alt', 'Kan Restaurant')} />
              </ItemImageContainer>
            )}
          </ItemContainer>
        </ItemCol>
      );
    });
  };

  render() {
    const { data = null, lang } = this.props;

    if (!data) return null;

    const backText = get(data, 'primary.backLinkText');
    const backLink = get(data, 'primary.backLink.document[0].data.url');

    return (
      <Container>
        <FlowerPattern
          src={flowerGold1}
          height="100px"
          width="auto"
          right="96%"
          bottom="10%"
          opacity={0.6}
        />
        <FlowerPattern
          src={flowerGold2}
          height="140px"
          width="auto"
          right="10px"
          bottom="50%"
          opacity={0.6}
        />

        <KanItemsRow type="flex" justify="center" align="top">
          {this.renderKanItems()}
        </KanItemsRow>
        {backText && backLink && (
          <KanItemsRow type="flex" justify="center" align="top">
            <BackButton to={getInternalUrl(backLink, lang)}>{backText.toUpperCase()}</BackButton>
          </KanItemsRow>
        )}
      </Container>
    );
  }
}

export default withTheme(KanItems);
